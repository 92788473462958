:root {
  --PrimaryColor: rgb(0,0,0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(60,60,60);
  --Hover: rgb(80,80,80);
}

.footer {
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
  padding: 2.5rem 2rem;
  text-align: justify;
}
@media screen and (max-width: 1000px) {
  .footer {
    padding: 2rem;
  }
}
.footer .layer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}
.footer .lita {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer .content {
  position: relative;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  color: white;
  z-index: 2;
}
.footer .content .wawan .text {
  margin-right: 2rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 500px) {
  .footer .content .wawan .text h3 {
    font-size: 1rem;
  }
}
.footer .content .wawan .text p {
  font-size: 1rem;
}
@media screen and (max-width: 1200px) {
  .footer .content .wawan .text p {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1000px) {
  .footer .content .wawan .text p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .wawan .text p {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 500px) {
  .footer .content .wawan .text p {
    font-size: 0.55rem;
  }
}
.footer .content .wawan .imgwawan {
  width: 200px;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1000px) {
  .footer .content .wawan {
    flex-direction: column-reverse;
  }
  .footer .content .wawan .text {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .footer .content .wawan .text h3 {
    position: absolute;
    order: 1;
    margin: 0;
    top: 0;
  }
  .footer .content .wawan .text p {
    order: 3;
    margin-top: 1rem;
  }
  .footer .content .wawan .imgwawan {
    margin-top: 3.5rem;
    order: 2;
    width: 160px;
  }
}
.footer .content hr {
  margin: 1rem 0;
  height: 3px;
  background-color: #fff;
  border: #fff solid 1px;
  border-radius: 5px;
}
.footer .content .social {
  justify-content: space-between;
  margin-bottom: 5rem;
  width: 100%;
  height: 100%;
  gap: 0.6rem;
}
.footer .content .social h4 {
  font-size: 1rem;
}
@media screen and (max-width: 1200px) {
  .footer .content .social h4 {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .social h4 {
    font-size: 0.8rem;
  }
}
.footer .content .social a {
  color: white;
  justify-items: center;
  align-items: center;
  display: flex;
  margin: 0.3rem 0;
}
.footer .content .social a:hover {
  color: black;
}
@media screen and (max-width: 1200px) {
  .footer .content .social a {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1000px) {
  .footer .content .social a {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .social a {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 500px) {
  .footer .content .social a {
    font-size: 0.6rem;
  }
}
.footer .content .social a .icon {
  margin-right: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .footer .content .social a .icon {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .social a .icon {
    font-size: 1.5rem;
  }
}
.footer .content .social .contact {
  margin-top: -2.3rem;
}
@media screen and (max-width: 1200px) {
  .footer .content .social .contact {
    margin-top: -2.1rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .social .contact {
    margin-top: -1.9rem;
  }
}
.footer .content .footerDiv {
  flex-direction: column;
  position: absolute;
  margin-top: 20rem;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0.2rem 2rem;
}
.footer .content .footerDiv small {
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}
@media screen and (max-width: 1000px) {
  .footer .content .footerDiv small {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 700px) {
  .footer .content .footerDiv small {
    font-size: 0.6rem;
  }
}
/*# sourceMappingURL=footer.css.map */