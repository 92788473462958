.home {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
}
.home .bgDiv {
  background-image: url(../../Assets/masfiqi.jpg);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  background-size: cover;
  background-position: center;
}
.home .homeContent {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 6rem 1.5rem;
  display: flex;
  row-gap: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
  z-index: 100;
}
.home .homeContent .textDiv {
  margin-top: 2rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 80%;
  margin-right: auto;
  text-align: center;
}
.home .homeContent .textDiv h1 {
  font-size: 3rem;
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .home .homeContent .textDiv h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 430px) {
  .home .homeContent .textDiv h1 {
    font-size: 1.4rem;
  }
}
.home .homeContent .textDiv h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 600px) {
  .home .homeContent .textDiv h3 {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 430px) {
  .home .homeContent .textDiv h3 {
    font-size: 0.6rem;
  }
}
.home .homeContent .textDiv p {
  font-weight: 100;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .home .homeContent .textDiv p {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 430px) {
  .home .homeContent .textDiv p {
    font-size: 0.5rem;
  }
}
.home .homeContent .textDiv .btnDaftar {
  margin: auto;
  width: 10rem;
  height: 2.5rem;
  border-radius: 10px;
  border: none;
  color: var(--PrimaryColor);
  background-color: var(--SecondaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .homeContent .textDiv .btnDaftar p {
  font-size: 1rem;
  margin: auto;
  padding: auto;
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv .btnDaftar p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .home .homeContent .textDiv .btnDaftar p {
    font-size: 0.6rem;
  }
}
.home .homeContent .textDiv .btnDaftar:hover {
  background-color: var(--Hover);
  color: var(--SecondaryColor);
}
.home .homeContent .textDiv .btnDaftar:active {
  background: none;
  border: 1px solid white;
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv .btnDaftar {
    border-radius: 8px;
    width: 9rem;
    height: 2.3rem;
  }
}
@media screen and (max-width: 600px) {
  .home .homeContent .textDiv .btnDaftar {
    border-radius: 6px;
    width: 7rem;
    height: 2rem;
  }
}

@media screen and (max-width: 884px) {
  .home .bgDiv {
    background-image: url(../../Assets/masfiqi2.jpg);
  }
}
@media screen and (max-width: 885px) {
  .home .homeContent .textDiv {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    text-align: center;
  }
  .home .homeContent .textDiv h1, .home .homeContent .textDiv h3 {
    margin-left: 0;
  }
  .home .homeContent .textDiv a .btnDaftar {
    margin-left: 0;
  }
}/*# sourceMappingURL=home.css.map */