:root {
    --PrimaryColor: rgb(0,0,0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(60, 60, 60);
    --Hover: rgb(80,80,80);
}

.header-scroll {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--SecondaryColor);
    width: 100%;
    padding: 0.5rem 4rem !important;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    transition: .5s ease-in;

    .logo {
        color: var(--PrimaryColor);
        font-weight: 600;
        align-items: center;
        justify-items: center;
        display: flex;

        .icon {
            width: 3.5rem;
            filter: drop-shadow(0 2px 4px rgba(140, 140, 141, 0.6));
        }

        h1 {
            font-family: 'jsMath-cmmi10', sans-serif;
            font-size: 2rem;
            margin-top: 5px;
            margin-left: 20px;
            margin-right: -8px;
            font-weight: bolder !important;
            text-shadow: 0 2px 4px rgba(140, 140, 141, 0.6);;
        }
    }

    .navBar {
        .navLists {      
            .navItem {
                .navLink {
                    color: var(--Text) !important;
                    font-size: .9rem;
                    font-weight: 600;
                    &:hover {
                        color: var(--PrimaryColor)!important;  
                    }
                }
            }
        }
    }
}

.header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    width: 100%;
    padding: 0.5rem 4rem !important;
    z-index: 1000;
    transition: .5s ease-in;

    .logo {
        color: var(--SecondaryColor);
        font-weight: 600;
        align-items: center;
        justify-items: center;
        display: flex;

        .icon {
            width: 3.5rem;
            filter: drop-shadow(0 2px 4px rgba(52, 52, 52, 0.6));
        }

        h1 {
            font-family: 'jsMath-cmmi10', sans-serif;
            font-size: 2rem;
            margin-top: 5px;
            margin-left: 20px;
            margin-right: -8px;
            font-weight: bolder !important;
            text-shadow: 0 2px 4px rgba(175, 175, 175, 0.6);
        }
    }

    .navBar {
        .navLists {      
            .navItem {
                .navLink {
                    color: var(--SecondaryColor) !important;
                    font-size: .9rem;
                    font-weight: 600;
                    &:hover {
                        color: var(--Text)!important;  
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .header-scroll {   
        padding: 0.5rem 1rem !important;

        .logo {
            .icon {
                width: 2.8rem;
            }
            
            h1 {
                font-size: 1.5rem !important;
                margin-right: -2px;
                margin-left: 10px;
            }
        }

        .navBar {
            position: absolute;
            background: var(--SecondaryColor);
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 0.5rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.9);
            transition: .5s ease-in-out;


            .navLists {
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: .5rem 0;
                
                .navItem {
                    padding-left: .5rem 0;
                    margin: .3rem 0;

                    .navLink {
                        color: var(--Text) !important;
                        font-size: .9rem;
                        font-weight: 600;
                        &:hover {
                            color: var(--PrimaryColor)!important;
                        }
                    }
                }
            }

            .closeNavbar {
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--PrimaryColor);
                &:hover {
                    color: var(--Hover);
                }
            }
        }

        .activeNavbar {
            top: 5rem;
        }

        .toggleNavbar {
            .icon {
                margin-top: 0.6rem;
                font-size: 25px;
                color: var(--PrimaryColor) !important;
            }
            &:hover.icon{
                color: var(--Hover);
            }
        }
    }

    

    .header {   
        padding: 0.5rem 1rem !important;

        .logo {
            .icon {
                width: 2.8rem;
            }
            
            h1 {
                font-size: 1.5rem !important;
                margin-right: -2px;
                margin-left: 10px;
            }
        }

        .navBar {
            position: absolute;
            background: var(--SecondaryColor);
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 0.5rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.9);
            transition: .5s ease-in-out;


            .navLists {
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: .5rem 0;
                
                .navItem {
                    padding-left: .5rem 0;
                    margin: .3rem 0;

                    .navLink {
                        color: var(--Text) !important;
                        font-size: .9rem;
                        font-weight: 600;
                        &:hover {
                            color: var(--PrimaryColor)!important;
                        }
                    }
                }
            }

            .closeNavbar {
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--PrimaryColor);
                &:hover {
                    color: var(--Hover);
                }
            }
        }

        .activeNavbar {
            top: 5rem;
        }
    }

    .toggleNavbar {
        .icon {
            margin-top: 0.6rem;
            font-size: 25px;
            color: var(--SecondaryColor);
        }
        &:hover.icon{
            color: var(--Hover) !important;
        }
    }
}

// =============== MEDIA QUERIES ==============

@media screen and (min-width: 769px) {
    .toggleNavbar, .closeNavbar {
        display: none;
    }

    .header-scroll {
        padding: 1.5rem 2rem;

        .logo {
            .icon {
                width: 3.8rem;
            }
        }

        .navBar {
            .navLists {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .navItem {
                    .navLink {
                        color: var(--Text);
                        padding: 0 0.45rem;
                        font-size: 1rem;
                        font-weight: 600;
                        display: inline-block;
                        position: relative;

                        &::after {
                            background: none repeat scroll 0 0 transparent;
                            bottom: 0;
                            content: "";
                            display: block;
                            height: 2px;
                            left: 50%;
                            position: absolute;
                            background: var(--PrimaryColor);
                            transition: width 0.3s ease 0s, left 0.3s ease 0s;
                            width: 0;
                        }

                        &:hover{
                            color: var(--PrimaryColor);
                            transition: .3s;
                        }

                        &:hover::after {
                            width: 100%; 
                            left: 0; 
                        }
                    }
                }               
            }
        }
    }

    .header {
        padding: 1.5rem 2rem;

        .logo {
            .icon {
                width: 3.8rem;
            }
        }

        .navBar {
            .navLists {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .navItem {
                    .navLink {
                        color: var(--SecondaryColor);
                        padding: 0 0.45rem;
                        font-size: 1rem;
                        font-weight: 600;
                        display: inline-block;
                        position: relative;

                        &::after {
                            background: none repeat scroll 0 0 transparent;
                            bottom: 0;
                            content: "";
                            display: block;
                            height: 2px;
                            left: 50%;
                            position: absolute;
                            background: var(--Text);
                            transition: width 0.3s ease 0s, left 0.3s ease 0s;
                            width: 0;
                        }

                        &:hover{
                            color: var(--Text);
                            transition: .3s;
                        }

                        &:hover::after {
                            width: 100%; 
                            left: 0; 
                        }
                    }
                }               
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .header-scroll {
        .navBar {
            .navLists {
                .navItem {
                    padding: 0 0.7rem;
                }
            }
        }
    }
    
    .header {
        .navBar {
            .navLists {
                .navItem {
                    padding: 0 0.7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1240px) {
    .header-scroll {
        padding: 1rem 8.5rem;
    }
    
    .header {
        padding: 1rem 8.5rem;
    }
}
