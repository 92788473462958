:root {
    --PrimaryColor: rgb(0,0,0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(60,60,60);
    --Hover: rgb(80,80,80);
}

.footer {
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
    margin: auto;
    min-height: 100vh;
    padding: 2.5rem 2rem;
    text-align: justify;

    @media screen and (max-width: 1000px) {
        padding: 2rem;
    }

    .layer {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.7);
        top: 0;
        bottom: 0;
        z-index: 1;
        mix-blend-mode: hard-light;
    }

    .lita {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
    }
    
    .content {
        position: relative;
        padding: 1rem 2rem;
        border-radius: 1rem;
        background: rgba($color: #FFFFFF, $alpha: 0.3);
        width: 100%;
        height: max-content;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        color: white;
        z-index: 2;

        .wawan {
            .text {
                margin-right: 2rem;
                margin-bottom: 4rem;
                
                h3 {
                    @media screen and (max-width: 500px) {
                        font-size: 1rem;
                    }
                }

                p {
                    font-size: 1rem;

                    @media screen and (max-width: 1200px) {
                        font-size: .9rem;
                    }
                    
                    @media screen and (max-width: 1000px) {
                        font-size: .8rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: .7rem;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: .55rem;
                    }
                }
            }

            .imgwawan {
                width: 200px;
                border-radius: .5rem;
                margin-bottom: 1rem;
            }

            @media screen and (max-width: 1000px) {
                flex-direction: column-reverse;

                .text {
                    order: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 0;
                    margin-bottom: 1rem;

                    h3 {
                        position: absolute;
                        order: 1;
                        margin: 0;
                        top: 0;
                    }

                    p {
                        order: 3;
                        margin-top: 1rem;
                    }
                }

                .imgwawan {
                    margin-top: 3.5rem;
                    order: 2;
                    width: 160px;
                }
            }
        }

        hr {
            margin: 1rem 0;
            height: 3px;
            background-color: #fff;
            border: #fff solid 1px;
            border-radius: 5px;
        }

        .social {
            justify-content: space-between;
            margin-bottom: 5rem;
            width: 100%;
            height: 100%;
            gap: .6rem;

            h4 {
                font-size: 1rem;

                @media screen and (max-width: 1200px) {
                    font-size: .9rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: .8rem;
                }
            }

            a {
                color: white;
                justify-items: center;
                align-items: center;
                display: flex;
                margin: .3rem 0;

                &:hover {
                    color: black;
                }

                @media screen and (max-width: 1200px) {
                    font-size: .9rem;
                }
                
                @media screen and (max-width: 1000px) {
                    font-size: .8rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: .7rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: .6rem;
                }

                .icon {
                    margin-right: .5rem;

                    @media screen and (max-width: 1200px) {
                        font-size: 1.7rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: 1.5rem;
                    }
                }
            }

            .contact {
                margin-top: -2.3rem;

                @media screen and (max-width: 1200px) {
                    margin-top: -2.1rem;
                }

                @media screen and (max-width: 700px) {
                    margin-top: -1.9rem;
                }
            }
        }

        .footerDiv {
            flex-direction: column;
            position: absolute;
            margin-top: 20rem;
            bottom: 0;
            left: 0;
            right: 0;
            background: white;
            padding: .2rem 2rem;

            small {
                font-size: .9rem;
                color: black;
                font-weight: 600;
                
                @media screen and (max-width: 1000px) {
                    font-size: .8rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: .6rem;
                }
            }
        }
    }
}