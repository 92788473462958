.home {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    min-height: 100vh;

    .bgDiv {
        background-image: url(../../Assets/masfiqi.jpg);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
        background-size: cover;
        background-position: center;
    }

    .homeContent {
        width: 100%;
        height: max-content;
        padding: 6rem 1.5rem;
        display: flex;
        row-gap: 3rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: white;
        z-index: 100;

        .textDiv {
            margin-top: 2rem;
            max-width: fit-content;
            width: 80%;
            margin-right: auto;
            text-align: center;  // Center-align text
            h1 {
                font-size: 3rem;

                // Adjust font size for smaller screens
                @media screen and (max-width: 885px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 600px) {
                    font-size: 1.5rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 1.4rem;
                }
            }

            h3 {
                font-size: 1.5rem;
                margin-bottom: 1rem;

                // Adjust font size for smaller screens
                @media screen and (max-width: 885px) {
                    font-size: 1.1rem;
                }

                @media screen and (max-width: 600px) {
                    font-size: .8rem;
                }
                
                @media screen and (max-width: 430px) {
                    font-size: .6rem;
                }
            }

            p {
                font-weight: 100;
                font-size: 1.2rem;
                text-align: center;
                margin-bottom: 2rem;

                // Adjust font size for smaller screens
                @media screen and (max-width: 885px) {
                    font-size: .8rem;
                }

                @media screen and (max-width: 600px) {
                    font-size: .55rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: .5rem;
                }
            }

            
            .btnDaftar {
                margin: auto;
                width: 10rem;
                height: 2.5rem;
                border-radius: 10px;
                border: none;
                color: var(--PrimaryColor);
                background-color: var(--SecondaryColor);
                display: flex; // Ensure flex properties are applied
                justify-content: center;
                align-items: center;

                p {
                    font-size: 1rem;
                    margin: auto;
                    padding: auto;

                    @media screen and (max-width: 885px) {
                        font-size: .8rem;
                    }
    
                    @media screen and (max-width: 600px) {
                        font-size: .6rem;
                    }
                }

                &:hover {
                    background-color: var(--Hover);
                    color: var(--SecondaryColor);
                }
                
                &:active {
                    background: none;
                    border: 1px solid white;
                }

                @media screen and (max-width: 885px) {
                    border-radius: 8px;
                    width: 9rem;
                    height: 2.3rem;
                }

                @media screen and (max-width: 600px) {
                    border-radius: 6px;
                    width: 7rem;
                    height: 2rem;
                }
            }
        
        }
    }
}

@media screen and (max-width: 884px) {
    .home {
        .bgDiv {
            background-image: url(../../Assets/masfiqi2.jpg);
        }
    }
}

@media screen and (max-width: 885px) {
    .home {
        .homeContent {
            .textDiv {
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-left: auto;
                text-align: center;  // Center-align text for better responsiveness

                h1, h3 {
                    margin-left: 0;
                }

                a {
                    .btnDaftar {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
