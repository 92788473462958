:root {
  --PrimaryColor: rgb(0,0,0);
  --SecondaryColor: rgb(255,255,255);
  --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
  --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
  --Text: rgb(60,60,60);
  --Hover: rgb(80,80,80);
}

.about {
  width: 100%;
  position: relative;
  display: grid;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
  padding: 2.5rem 8rem;
  text-align: justify;
}
.about .firstP {
  flex-direction: row-reverse;
}
.about .firstP img {
  width: 35%;
}
@media screen and (max-width: 1400px) {
  .about .firstP img {
    width: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .about .firstP img {
    width: 60%;
  }
}
.about .firstP .P1 {
  padding-left: 2rem;
  margin-top: 1rem;
}
.about .firstP .P1 h1 {
  font-size: 2.5rem;
  margin-top: -4rem;
  position: relative;
  width: -moz-max-content;
  width: max-content;
  z-index: 2;
}
.about .firstP .P1 h1::after {
  content: "";
  width: 101px;
  height: 8px;
  position: absolute;
  background: var(--Gradient1);
  border-radius: 10px;
  left: 0;
  bottom: 0;
  z-index: -1;
}
@media screen and (max-width: 1000px) {
  .about .firstP .P1 h1::after {
    left: 40px;
    right: 0;
    height: 7px;
  }
}
@media screen and (max-width: 700px) {
  .about .firstP .P1 h1::after {
    left: 25px;
    right: 0;
  }
}
@media screen and (max-width: 500px) {
  .about .firstP .P1 h1::after {
    left: 20px;
    right: 0;
    height: 6px;
  }
}
@media screen and (max-width: 1400px) {
  .about .firstP .P1 h1 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .about .firstP .P1 h1 {
    font-size: 2rem;
  }
}
.about .firstP .P1 p {
  margin-top: 1rem;
  font-size: 1.2rem;
}
@media screen and (max-width: 1400px) {
  .about .firstP .P1 p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .about .firstP .P1 p {
    font-size: 0.9rem;
  }
}
.about .secondP {
  margin-top: 1rem;
  flex-direction: row-reverse;
}
.about .secondP img {
  width: 35%;
}
@media screen and (max-width: 1400px) {
  .about .secondP img {
    width: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .about .secondP img {
    width: 60%;
  }
}
.about .secondP p {
  font-size: 1.2rem;
  padding-right: 2rem;
  margin-top: -5rem;
}
@media screen and (max-width: 1400px) {
  .about .secondP p {
    font-size: 1rem;
    margin-top: -4rem;
  }
}
@media screen and (max-width: 1200px) {
  .about .secondP p {
    font-size: 0.9rem;
    margin-top: -1rem;
  }
}
@media screen and (max-width: 1000px) {
  .about {
    padding: 2rem;
  }
  .about .firstP {
    flex-direction: column-reverse;
  }
  .about .firstP .P1 {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
  .about .firstP .P1 h1 {
    position: absolute;
    order: 1;
    margin: 1rem 0;
    top: 0;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 700px) {
  .about .firstP .P1 h1 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 500px) {
  .about .firstP .P1 h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1000px) {
  .about .firstP .P1 p {
    order: 3;
    text-align: justify;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 700px) {
  .about .firstP .P1 p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 500px) {
  .about .firstP .P1 p {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 1000px) {
  .about .firstP img {
    margin-top: 7rem;
    order: 2;
    width: 100%;
  }
  .about .secondP {
    flex-direction: column;
  }
  .about .secondP img {
    width: 100%;
  }
  .about .secondP p {
    padding: 0;
    text-align: justify;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 700px) {
  .about .secondP p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1000px) and (max-width: 500px) {
  .about .secondP p {
    font-size: 0.7rem;
  }
}/*# sourceMappingURL=about.css.map */