:root {
    --PrimaryColor: rgb(0,0,0);
    --SecondaryColor: rgb(255,255,255);
    --Gradient1: linear-gradient(to right, rgb(0,0,0), rgb(102,102,102));
    --Gradient2: linear-gradient(to right, rgb(255,255,255), rgb(62,62,62));
    --Text: rgb(60,60,60);
    --Hover: rgb(80,80,80);
}

.about {
    width: 100%;
    position: relative;
    display: grid;
    justify-content: center;
    margin: auto;
    min-height: 100vh;
    padding: 2.5rem 8rem;
    text-align: justify;

    .firstP {
        flex-direction: row-reverse;

        img {
            width: 35%;

            @media screen and (max-width: 1400px) {
                width: 45%;
            }

            @media screen and (max-width: 1200px) {
                width: 60%;
            }
        }

        .P1 {
            padding-left: 2rem;
            margin-top: 1rem;

            h1 {
                font-size: 2.5rem;
                margin-top: -4rem;
                position: relative;
                width: max-content;
                z-index: 2;

                &::after {
                    content: "";
                    width: 101px;
                    height: 8px;
                    position: absolute;
                    background: var(--Gradient1);
                    border-radius: 10px;
                    left: 0;
                    bottom: 0;
                    z-index: -1;

                    @media screen and (max-width: 1000px) {
                        left: 40px;
                        right: 0;
                        height: 7px;
                    }

                    @media screen and (max-width: 700px) {
                        left: 25px;
                        right: 0;
                    }

                    @media screen and (max-width: 500px) {
                        left: 20px;
                        right: 0;
                        height: 6px;
                    }
                }

                @media screen and (max-width: 1400px) {
                    font-size: 2.2rem;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 2rem;
                }
            }

            p {
                margin-top: 1rem;
                font-size: 1.2rem;

                @media screen and (max-width: 1400px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 1200px) {
                    font-size: .9rem;
                }
            }
        }
    }

    .secondP {
        margin-top: 1rem;
        flex-direction: row-reverse;

        img {
            width: 35%;

            @media screen and (max-width: 1400px) {
                width: 45%;
            }

            @media screen and (max-width: 1200px) {
                width: 60%;
            }
        }

        p {
            font-size: 1.2rem;
            padding-right: 2rem;
            margin-top: -5rem;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
                margin-top: -4rem;
            }

            @media screen and (max-width: 1200px) {
                font-size: .9rem;
                margin-top: -1rem;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        padding: 2rem;

        .firstP {
            flex-direction: column-reverse;

            .P1 {
                order: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 0;

                h1 {
                    position: absolute;
                    order: 1;
                    margin: 1rem 0;
                    top: 0;
                    font-size: 2.5rem;

                    @media screen and (max-width: 700px) {
                        font-size: 2.2rem;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 2rem;
                    }
                }

                p {
                    order: 3;
                    text-align: justify;
                    margin-top: 1rem;

                    @media screen and (max-width: 700px) {
                        font-size: .8rem;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: .7rem;
                    }
                }
            }

            img {
                margin-top: 7rem;
                order: 2;
                width: 100%;
            }
        }
        
        .secondP {
            flex-direction: column;

            img {
                width: 100%;
            }

            p {
                padding: 0;
                text-align: justify;
                margin-top: 1rem;

                @media screen and (max-width: 700px) {
                    font-size: .8rem;
                }

                @media screen and (max-width: 500px) {
                    font-size: .7rem;
                }
            }
        }

    }
}